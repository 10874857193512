import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { MainContainer } from "../elements/Container"
import {
  MainTitle,
  StaticText,
  StaticTitle,
  Alata,
  LinkBack,
} from "../elements/Text"

const Section = styled.div`
  width: 32%;
  text-align: justify;
  margin: 0 auto;
  margin-top: 5vh;
  @media only screen and (max-width: 900px) {
    width: 90%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
`

const PagePolitiques = ({ data, path }) => {
  const prismic = data.prismicPolitiques.data

  return (
    <Layout noMenu={true} headerString={[""]} hasScrolled={true}>
      <SEO
        title={prismic.page_title.text}
        description={prismic.page_description.text}
        image={prismic.og_image.url}
        location={path}
      />
      <MainContainer>
        <MainTitle>{prismic.main_title.text}</MainTitle>
        {prismic.sections.map((section, index) => (
          <Section key={index}>
            <StaticTitle>{section.section_title.text}</StaticTitle>
            <StaticText>
              {section.section_text.raw.map(content =>
                content.text.includes("novlini") ? (
                  <>
                    {content.text.split("novlini").map((item, i) => (
                      <span key={i}>
                        {item.split("\n").map((text, i) => (
                          <span key={i}>
                            {text}
                            {i < item.split("\n").length - 1 && <br />}
                          </span>
                        ))}
                        {i === 0 && <Alata>novlini</Alata>}
                      </span>
                    ))}
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    {content.text.split("\n").map((item, i) => (
                      <span key={i}>
                        {item}
                        <br />
                      </span>
                    ))}
                    <br />
                  </>
                )
              )}
            </StaticText>
          </Section>
        ))}
        <ButtonContainer>
          <LinkBack className="click" to="/">
            retour au site
          </LinkBack>
        </ButtonContainer>
      </MainContainer>
    </Layout>
  )
}

export default PagePolitiques

export const query = graphql`
  query {
    prismicPolitiques {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        og_image {
          url
        }
        main_title {
          text
        }
        sections {
          section_text {
            raw
          }
          section_title {
            text
          }
        }
      }
    }
  }
`
